import React from "react";
import logoFooter from "../../assets/LogoFooter2.png";
import mcrpro from "../../assets/mcrpro.png";
import "./Footer.css";
import { NavLink } from "react-router-dom";

function footer() {
    return (
        <div className="footer">
            {/* <div className="contactt">
                {" "}
                <NavLink to="/contact">Contact</NavLink>
            </div> */}

            <div className="mcrpro">
                {" "}
                <a
                    href="https://mcrpro.fr/"
                    className="mcrpro"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src={mcrpro}
                        alt="logo"
                        className="logoFooter"
                        width="100"
                    />
                </a>
            </div>

            <div className="footerLogo">
                <img
                    src={logoFooter}
                    alt="logo"
                    className="logoFooter"
                    width="100"
                />
            </div>
            {/* <div className="footerText">MCR Plus. Second Oeuvre.</div> */}
        </div>
    );
}

export default footer;
